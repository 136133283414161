<script>
	import {getBuffets} from "@/functions/api/content";
	export default {
		created() {
			this.checkBuffets();
		},
		methods: {
			checkBuffets() {
				setTimeout(() =>
					getBuffets().then(buffets => {
						if(buffets.find(buffet => buffet.open)) {
							this.$router.replace('/sector-selector');
							return;
						}
						this.checkBuffets();
					}),
				10000);
			},
		}
	}
</script>
<template>
	<div class="container">
		<div>
			<img class="offline-img" src="../../assets/images/offline_v2.svg"/>
			<h3 class="bold-title">{{$t('app-offline-title-header')}}</h3>
			<h3>{{$t('app-offline-title')}}</h3>
		</div>
	</div>
</template>
<style scoped>
	.container {
		height: 100%;
		min-height: calc(100vh - 60px);
		text-align: center;
		display: flex;
	}
	.container > div {
		margin: auto;
		display: flex;
		flex-direction: column;
	}
	.container img {
		margin-bottom: 80px;
	}
	.bold-title {
		font-weight: 1000;
	}
	.offline-img {
		width: 65%;
		text-align: center;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
</style>